import React, { useState, useContext } from "react"
import { Link } from "gatsby"
import { InstantSearch } from "react-instantsearch-dom"

import {
  searchClient,
  searchStateToUrl,
} from "../../utils/algolia/searchClient"
import { Locale } from "../../context"
import { linkResolver } from "../../utils/linkResolver"
import { CustomMenuSelect } from "../../components/search"

import "instantsearch.css/themes/reset.css"

const Search = ({ data }) => {
  let { locale } = useContext(Locale)

  const [searchState, setSearchState] = useState("")

  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={`${process.env.GATSBY_ALGOLIA_INDEX_NAME}_${locale}`}
      onSearchStateChange={searchState =>
        setSearchState(searchStateToUrl(searchState, locale, true))
      }
    >
      <div className="flex flex-wrap w-full max-w-4xl bg-white border border-gray-200 rounded-lg rounded-t-lg shadow-lg pointer-events-auto sm:border-none">
        <div className="w-full px-5 pt-4 pb-3 rounded-t-lg lg:flex-1">
          <label className="block text-xs font-bold leading-none tracking-wide text-gray-400 uppercase">
            {data.home.propertyStatus}
          </label>
          <CustomMenuSelect
            attribute="propertyStatus.name"
            placeholder={data.home.propertyStatusPlaceholder}
            home
          />
        </div>
        <div className="w-1/2 px-5 pt-4 pb-3 bg-white lg:flex-1 lg:bg-transparent">
          <label className="block text-xs font-bold leading-none tracking-wide text-gray-400 uppercase">
            {data.home.propertyType}
          </label>
          <CustomMenuSelect
            attribute="propertyType.name"
            placeholder={data.home.propertyTypePlaceholder}
            limit={999}
            transformItems={items =>
              items.sort((a, b) => a.label.localeCompare(b.label))
            }
            home
          />
        </div>
        <div className="w-1/2 px-5 pt-4 pb-3 bg-white lg:flex-1 lg:bg-transparent">
          <label className="block text-xs font-bold leading-none tracking-wide text-gray-400 uppercase">
            {data.home.country}
          </label>
          <CustomMenuSelect
            attribute="city.country.name"
            placeholder={data.home.countryPlaceholder}
            limit={999}
            transformItems={items =>
              items.sort((a, b) => a.label.localeCompare(b.label))
            }
            home
          />
        </div>
        <Link
          to={linkResolver(data.home.searchLink, `/${searchState}`)}
          className="flex items-center justify-center w-full h-auto px-10 py-4 font-semibold text-white transition duration-200 rounded-b-lg cursor-pointer bg-black hover:bg-opacity-75 focus:outline-none lg:w-auto lg:rounded-b-none lg:rounded-r-lg"
        >
          {data.home.search}
        </Link>
      </div>
    </InstantSearch>
  )
}

export default Search
