import React from "react"
import { graphql } from "gatsby"
import { HelmetDatoCms } from "gatsby-source-datocms"

import Hero from "./Hero"

import Layout from "../../components/Layout"
import Content from "../../components/Content"
import Modal from "../../components/HomepageModal/Modal"

const Index = ({ data }) => (
  <Layout transparentHeader>
    <HelmetDatoCms
      htmlAttributes={{
        lang: data.home.locale,
      }}
      seo={data.home.seoMetaTags}
    >
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "Organization",
          name: "iKeys Realty",
          email: "team@ikeys-realty.com",
          telephone: "+230 5 744-9833",
          url: `https://ikeys-realty.com/${data.home.locale}`,
          logo: "https://ikeys-realty.com/ikeys-logo.png",
          sameAs: data.social.nodes.map(social => social.link),
        })}
      </script>
      <link rel="alternate" hreflang="en" href="https://ikeys-realty.com/en" />
      <link rel="alternate" hreflang="fr" href="https://ikeys-realty.com/fr" />
    </HelmetDatoCms>
    <div className="home">
      {/* <Modal /> */}
      <Hero data={data} />
      <Content content={data.home.content} />
    </div>
  </Layout>
)

export const query = graphql`
  query HomeQuery($locale: String!) {
    home: datoCmsHome(locale: { eq: $locale }) {
      ...Home
    }
    social: allDatoCmsSocial(filter: { locale: { eq: $locale } }) {
      nodes {
        ...Social
      }
    }
  }
`

export default Index
